import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24bc6117"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"color":"orangered"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Codemirror = _resolveComponent("Codemirror")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_back_top = _resolveComponent("a-back-top")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, { bordered: false }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tabs, {
        activeKey: _ctx.activeKey,
        "onUpdate:activeKey": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.activeKey) = $event)),
        "tab-position": _ctx.tabPosition,
        onChange: _ctx.onTabChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            key: "1",
            tab: "配置"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form, {
                model: _ctx.formState,
                "label-col": _ctx.labelCol,
                "wrapper-col": _ctx.wrapperCol
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, { label: "输入" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Codemirror, {
                        ref: "originCmRef",
                        value: _ctx.formState.origin,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.origin) = $event)),
                        options: _ctx.cmOptions,
                        height: 400,
                        border: "",
                        onChange: _ctx.onJsonChange
                      }, null, 8, ["value", "options", "onChange"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { "wrapper-col": { span: 14, offset: 4 } }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        type: "primary",
                        onClick: _ctx.onSubmit
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("确定")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "label-col", "wrapper-col"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_tab_pane, {
            key: "2",
            tab: "预览"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Codemirror, {
                value: _ctx.result,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.result) = $event)),
                options: _ctx.resultCmOptions,
                height: 800,
                border: ""
              }, null, 8, ["value", "options"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_back_top)
        ]),
        _: 1
      }, 8, ["activeKey", "tab-position", "onChange"]),
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.parseError), 1)
    ]),
    _: 1
  }))
}